import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleScrollToSection = (sectionId) => {
    navigate("/"); 
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); 
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">{t('footer.logo')}</div>
        <p className="footer-description">
          {t('footer.supportText')} <a href="mailto:support@trendygameonline.com">support@trendygameonline.com</a>
        </p>
        <p>© 2024 trendygameonline.com {t('footer.rightsReserved')}</p>
        <p>{t('footer.companyAddress')}</p>
        <p>+31 20 570 3170</p>
        <p>{t('footer.paymentMethod')}</p>
        <div className="footer-links">
          <Link to="/terms">{t('footer.terms')}</Link>
          <Link to="/privacy">{t('footer.privacy')}</Link>
          <Link 
            to="/" 
            onClick={(e) => {
              e.preventDefault();
              handleScrollToSection('contact-us');
            }}
          >
            {t('footer.contact')}
          </Link>
          <Link to="/about">{t('footer.about')}</Link>
          <Link to="/cookie-policy">{t('footer.cookiePolicy')}</Link>
          <Link 
            to="/" 
            onClick={(e) => {
              e.preventDefault();
              handleScrollToSection('pricing');
            }}
          >
            {t('footer.pricing')}
          </Link>
          <Link to="/unsubscribe-refund">{t('footer.unsubscribe')}</Link>
        </div>
        <div className="footer-bottom">
          <p>{t('footer.exploreText')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
