import React from 'react';
import { useNavigate } from 'react-router-dom';
import './GameCard.css';
import { useTranslation } from 'react-i18next';

const GameCard = ({ title, image, type }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleActionClick = () => {
    navigate('/signin'); // Redirects to sign-in page
  };

  return (
    <div className="game-card" style={{ backgroundImage: `url(${image})` }}>
      <div className="game-card-overlay">
        <h3 className="game-card-title">{title}</h3>
        <button className="game-card-btn" onClick={handleActionClick}>
          {type === 'game' ? t('gameCard.playNow') : t('gameCard.watchNow')}
        </button>
      </div>
    </div>
  );
};

export default GameCard;
