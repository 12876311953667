import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SignIn.css';

const SignIn = () => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const isValidLithuanianNumber = (number) => {
    const lithuanianPhoneRegex = /^(\+370|370)\d{8}$/;
    return lithuanianPhoneRegex.test(number);
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (isValidLithuanianNumber(phoneNumber)) {
      setMessage(t('signin.successMessage'));

      setTimeout(() => {
        setMessage('');
        window.location.href = 'https://ltv.trendygameonline.com/xbso8';
      }, 3000);
    } else {
      setError(t('signin.errorMessage'));
    }
  };

  const handleSubscriptionRedirect = () => {
    window.location.href = 'https://ltv.trendygameonline.com/xbso8';
  };

  return (
    <div className="sign-in-page">
      <h1>{t('signin.title')}</h1>
      <p className="intro-text">{t('signin.introText')}</p>

      <form onSubmit={handleSignIn}>
        <div className="input-group">
          <label htmlFor="phone-number">{t('signin.phoneNumberLabel')}</label>
          <input
            type="text"
            id="phone-number"
            placeholder={t('signin.phoneNumberPlaceholder')}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="sign-in-btn">{t('signin.signInButton')}</button>
      </form>

      {message && <p className="success-message">{message}</p>}
      {error && <p className="error-message">{error}</p>}

      <div className="extra-info">
  <h2>{t('signin.noAccountTitle')}</h2>
  <p>
    <span>{t('signin.noAccountText')}</span>
    <span onClick={handleSubscriptionRedirect} className="subscription-link">
      {t('signin.subscribeHere')}
    </span>
  </p>
</div>


      <div className="terms-note">
        <h2>{t('signin.agreementTitle')}</h2>
        <ul>
          <li><a href="/terms">{t('signin.terms')}</a></li>
          <li><a href="/privacy">{t('signin.privacy')}</a></li>
          <li><a href="/cookie-policy">{t('signin.cookiePolicy')}</a></li>
        </ul>
      </div>
    </div>
  );
};

export default SignIn;
