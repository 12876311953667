import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ContactUs.css';

const ContactUs = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", { name, email, message });
    
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <section id="contact-us" className="contact-us">
      <h2>{t('contact.title')}</h2>
      <p>{t('contact.subtitle')}</p>
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          placeholder={t('contact.namePlaceholder')} 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
          required 
        />
        <input 
          type="email" 
          placeholder={t('contact.emailPlaceholder')} 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />
        <textarea 
          placeholder={t('contact.messagePlaceholder')} 
          value={message} 
          onChange={(e) => setMessage(e.target.value)} 
          required 
        ></textarea>
        <button type="submit">{t('contact.submitButton')}</button>
      </form>
    </section>
  );
};

export default ContactUs;
