import React from 'react';
import './SubscriptionBenefits.css';
import { useTranslation } from 'react-i18next';

const handleSubscribeClick = () => {
  window.location.href = 'https://ltv.trendygameonline.com/xbso8'; 
};

const SubscriptionBenefits = () => {
  const { t } = useTranslation();

  return (
    <section className="subscription-benefits">
      <h2>{t('subscriptionBenefits.title')}</h2>
      <ul>
        <li>{t('subscriptionBenefits.benefit1')}</li>
        <li>{t('subscriptionBenefits.benefit2')}</li>
        <li>{t('subscriptionBenefits.benefit3')}</li>
        <li>{t('subscriptionBenefits.benefit4')}</li>
      </ul>
      <button className="cta-btn" onClick={handleSubscribeClick}>
        {t('subscriptionBenefits.subscribeButton')}
      </button>
    </section>
  );
};

export default SubscriptionBenefits;
