import React from 'react';
import './GameGallery.css';
import GameCard from '../GameCard/GameCard';
import { useTranslation } from 'react-i18next';

const GameGallery = () => {
  const { t } = useTranslation();

  const gameData = [
    {
      title: 'Casino Royale',
      image: '/games/Casino_Royale.jpg',
    },
    {
      title: 'Evil Pumpkins',
      image: '/games/Evil_Pumpkins.jpg',
    },
    {
      title: 'Outerspace Panic',
      image: '/games/Outerspace_Panic.jpg',
    },
    {
      title: 'Sunset Games',
      image: '/games/Sunset_Games.jpg',
    },
    {
      title: 'Color Rush',
      image: '/games/Color_Rush.jpg',
    },
    {
      title: 'Oriental Express',
      image: '/games/Oriental_Express.jpg',
    },
    {
      title: 'Cube Escape',
      image: '/games/Cube_Escape.jpg',
    },
    {
      title: 'Spooky Graveyard',
      image: '/games/Spooky_Graveyard.jpg',
    },
    {
      title: 'Lonely Nights',
      image: '/games/Lonely_Nights.jpg',
    },
    {
      title: 'Addiction Vortex',
      image: '/games/Addiction_Vortex.jpg',
    },
    {
      title: 'Winter Wonderland',
      image: '/games/Winter_Wonderland.jpg',
    },
    {
      title: 'Monster Ball',
      image: '/games/Monster_Ball.jpg',
    },
  ];

  return (
    <section id="games" className="game-gallery"> 
      <h2>{t('gameGallery.title')}</h2>
      <div className="gallery-grid">
        {gameData.map((game, index) => (
          <GameCard 
            key={index} 
            title={game.title} 
            image={game.image}
            type="game"
          />
        ))}
      </div>
    </section>
  );
};

export default GameGallery;
