import React from 'react';
import GameCard from '../GameCard/GameCard';
import './VideoGallery.css';
import { useTranslation } from 'react-i18next';

const videoData = [
  {
    title: 'One Day in Bali',
    image: '/video/One_Day_in_Bali.png',
    link: '/videos/watch/Adventure/2DQrKa'
  },
  {
    title: "One Day in Ecuador's Shoreline",
    image: '/video/One_Day_in_Ecuador.png',
    link: '/videos/watch/Adventure/pkr9pa'
  },
  {
    title: 'One Day in Galway & Cliffs of Moher',
    image: '/video/One_Day_in_Galway_&_Cliffs_of_Moher.png',
    link: '/videos/watch/Adventure/AJZxra'
  },
  {
    title: 'One Day in Iceland',
    image: '/video/One_Day_in_Iceland.png',
    link: '/videos/watch/Adventure/KkPQzJ'
  },
  {
    title: 'One Day in Madeira, Portugal',
    image: '/video/One_Day_in_Madeira,_Portugal.png',
    link: '/videos/watch/Adventure/7D98yJ'
  },
  {
    title: "One Day in Malta's Beaches",
    image: '/video/One_Day_in_Malta.png',
    link: '/videos/watch/Adventure/RD8XqD'
  },
  {
    title: 'One Day in Santorini, Greece',
    image: '/video/One_Day_in_Santorini,_Greece.png',
    link: '/videos/watch/Adventure/MkewOD'
  },
  {
    title: 'Animals Up Close',
    image: '/video/Animals_Up_Close.png',
    link: '/videos/watch/Animals/2DQL8J'
  },
  {
    title: 'Farm Animals',
    image: '/video/Farm_Animals.png',
    link: '/videos/watch/Animals/pkr1ea'
  },
  {
    title: 'Animals in the Zoo',
    image: '/video/Animals_in_the_Zoo.png',
    link: '/videos/watch/Animals/AJZVLJ'
  },
  {
    title: 'One Day in Paris',
    image: '/video/One_Day_in_Paris.png',
    link: '/videos/watch/City+Tour/oklenJ'
  },
  {
    title: 'One Day in Rome',
    image: '/video/One_Day_in_Rome.png',
    link: '/videos/watch/City+Tour/vDYvEa'
  }
];

const VideoGallery = () => {
  const { t } = useTranslation();

  return (
    <section id="videos" className="video-gallery"> 
      <h2>{t('videoGallery.title')}</h2>
      <div className="video-gallery-grid">
        {videoData.map((video, index) => (
          <GameCard 
            key={index} 
            title={video.title} 
            image={video.image} 
            type="video"
          />
        ))}
      </div>
    </section>
  );
};

export default VideoGallery;
