import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import lt from './locales/lt.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      lt: { translation: lt }
    },
    lng: 'lt', // Set Lithuanian as the default language
    fallbackLng: 'en', // Optional: use English if translation is missing
    interpolation: { escapeValue: false },
  });

export default i18n;
