import React from 'react';
import { useTranslation } from 'react-i18next';
import './Testimonials.css';

const Testimonials = () => {
  const { t } = useTranslation();

  const testimonials = [
    {
      name: t('testimonials.sarah.name'),
      feedback: t('testimonials.sarah.feedback'),
    },
    {
      name: t('testimonials.john.name'),
      feedback: t('testimonials.john.feedback'),
    },
    {
      name: t('testimonials.emily.name'),
      feedback: t('testimonials.emily.feedback'),
    },
  ];

  return (
    <section className="testimonials">
      <h2>{t('testimonials.title')}</h2>
      <div className="testimonial-list">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <p>"{testimonial.feedback}"</p>
            <p>- {testimonial.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
