import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Navbar.css';

const Navbar = () => {
  const { t, i18n } = useTranslation();  // Import the translation hook
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [language, setLanguage] = useState('lt');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScrollToSection = (sectionId) => {
    navigate("/");
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);  // Change the language with i18n
  };

  return (
    <nav className="navbar">
<Link to="/" className="logo">Trendy Game</Link>
    
      <div className={`nav-links ${isOpen ? 'active' : ''}`}>
        <Link to="/about">{t('navbar.about')}</Link>
        <Link to="/" onClick={() => handleScrollToSection('games')}>{t('navbar.games')}</Link>
        <Link to="/" onClick={() => handleScrollToSection('videos')}>{t('navbar.videos')}</Link>
        <button 
          className="subscribe-btn" 
          onClick={() => window.location.href = "https://ltv.trendygameonline.com/xbso8"}
        >
          {t('navbar.subscribe')}
        </button>
        <Link to="/" onClick={() => handleScrollToSection('pricing')}>{t('navbar.pricing')}</Link>
        <Link to="/signin">{t('navbar.signIn')}</Link>
        <Link to="/" onClick={() => handleScrollToSection('contact-us')}>{t('navbar.contactUs')}</Link>

        {/* Language Selector */}
        <div className="lang-selector">
          <select value={language} onChange={handleLanguageChange}>
            <option value="en">EN</option>
            <option value="lt">LT</option>
          </select>
        </div>
      </div>

      <button className="hamburger" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>
    </nav>
  );
};

export default Navbar;
