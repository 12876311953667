import React from 'react';
import { useTranslation } from 'react-i18next';
import './Terms.css';

const Terms = () => {
  const { t } = useTranslation();

  return (
    <div className="terms-page">
      <h1>{t('terms.title')}</h1>
      <p><strong>{t('terms.lastUpdated')}</strong> {t('terms.lastUpdatedDate')}</p>
      <p><strong>{t('terms.effectiveDateLabel')}</strong> {t('terms.effectiveDate')}</p>
      <h2>{t('terms.ownerAndDataController')}</h2>
      <p>{t('terms.introText')}</p>
      <p><strong>{t('terms.company')}</strong> Prizeflix BV</p>
      <p><strong>{t('terms.companyRegNum')}</strong> 68940211</p>
      <p><strong>{t('terms.managingDirector')}</strong> Alexis Maurits Bartelds</p>
      <p><strong>{t('terms.address')}</strong> Van Diemenstraat 356, 1013 CR, Amsterdam, Netherlands</p>
      <p><strong>{t('terms.contactEmail')}</strong> <a href="mailto:support@trendygameonline.com">support@trendygameonline.com</a></p>
      <p><strong>{t('terms.telephone')}</strong> +31 20 570 3170</p>

      <h2>{t('terms.introduction')}</h2>
      <p>{t('terms.introductionText')}</p>

      <h2>{t('terms.overview')}</h2>
      <p>{t('terms.overviewText')}</p>

      <h2>{t('terms.accountRegistration')}</h2>
      <p>{t('terms.accountRegistrationText')}</p>

      <h2>{t('terms.feesBilling')}</h2>
      <p>{t('terms.feesBillingText')}</p>

      <h3>{t('terms.subscriptionFees')}</h3>
      <p>{t('terms.subscriptionFeesText')}</p>

      <h3>{t('terms.freeTrialsPromotions')}</h3>
      <p>{t('terms.freeTrialsPromotionsText')}</p>

      <h2>{t('terms.serviceAvailability')}</h2>
      <p>{t('terms.serviceAvailabilityText')}</p>

      <h2>{t('terms.conditionsOfUse')}</h2>
      <p>{t('terms.conditionsOfUseText')}</p>

      <h2>{t('terms.terminationCancellation')}</h2>
      <p>{t('terms.terminationCancellationText')}</p>

      <h2>{t('terms.refundPolicy')}</h2>
      <p>{t('terms.refundPolicyText')}</p>

      <h2>{t('terms.dataPrivacy')}</h2>
      <p>{t('terms.dataPrivacyText')}</p>

      <h2>{t('terms.limitationLiability')}</h2>
      <p>{t('terms.limitationLiabilityText')}</p>

      <h2>{t('terms.intellectualProperty')}</h2>
      <p>{t('terms.intellectualPropertyText')}</p>

      <h2>{t('terms.amendments')}</h2>
      <p>{t('terms.amendmentsText')}</p>

      <h2>{t('terms.governingLaw')}</h2>
      <p>{t('terms.governingLawText')}</p>

      <h2>{t('terms.contactUs')}</h2>
      <p>{t('terms.contactUsText')} <a href="mailto:support@trendygameonline.com">support@trendygameonline.com</a> {t('terms.orCall')} +31 20 570 3170.</p>
    </div>
  );
};

export default Terms;
