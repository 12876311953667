import React from 'react';
import './FAQ.css';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const { t } = useTranslation();

  const faqs = [
    {
      question: t('faq.subscribeQuestion'),
      answer: t('faq.subscribeAnswer'),
    },
    {
      question: t('faq.devicesQuestion'),
      answer: t('faq.devicesAnswer'),
    },
    {
      question: t('faq.cancelQuestion'),
      answer: t('faq.cancelAnswer'),
    },
    {
      question: t('faq.trialQuestion'),
      answer: t('faq.trialAnswer'),
    },
  ];

  return (
    <section className="faq">
      <h2>{t('faq.title')}</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
